*{
  margin: 0;
  padding: 0;
}

body{
  overflow-x:hidden;
  background: #FEF8EE;
}


/* LOADER */
#loaderSection{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loaderImg{
  animation: rockHands .2s infinite alternate;;
}
@keyframes rockHands {
  from{
    width: 15rem;
  }
  to{
    width: 14rem;
  }
}


/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #FEF8EE;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--black);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--black);
}


/* BRAND */
#h1{
  font-family: 'Paytone One', sans-serif;
  font-size: 46px;
  line-height: 117.7%;
  color: var(--black);
}
#h2{
  font-family: Paytone One;
  font-size: 55px;
  line-height: 117.7%;
  color: var(--black);
}
#h4{
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 900;
  font-size: 21px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
#h5{
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 143.7%;
  letter-spacing: 1px;
  color: var(--black);
}
#h5:hover{
  transform: translateY(-2px);
}
#text{
  font-family: IBM Plex Sans;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--black);
}
a{
  text-decoration: none;
  color:black;
}
#nameText{
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--darkGreen);
}


/* ALL BUTTONS */
button{
  text-align: center;
  border: 2.5px solid var(--black);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 11px 18px;
  cursor: pointer;
}
button:hover{
  transform: translateY(-2px);
}
.joinBtn{
  background: var(--green);
}
.discordBtn{
  background: var(--blue);
}
.joinBtn:hover{
  opacity: .88;
}
.discordBtn:hover{
  opacity: .88;
}

/* SOCIAL MEDIA */
#socialMedia{
  display: flex;
  justify-content: space-between;
}
#socialImg{
  margin:4px;
  width: 3rem;
}
#socialImg:hover{
  transform: translateY(-2px);
}


/* NAV */
#navCont{
  display: flex;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 12vh;
  z-index: 1000;
}
#navName{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}
#name{
  font-family: 'Luckiest Guy', cursive;
  font-size: 29px;
  margin-top: 20px;
  color: var(--black);
}
#navLink{
  display: flex;
  align-items: center;
  width: auto;
}
#navLink div{
  margin:4px;
}
/*Nav Bar styles after scroll*/
.scrolling-active{
  background-color: #FFFCF8;
  box-shadow: 0 4px 1rem rgba(0,0,0,.2);
}


/* Footer */
#footer {
  background-color: var(--black);
  color: var(--offWhite);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 38vh;
}
#footerCont{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
  gap: 1.2rem;
}
#footerCont #name{
  color: var(--offWhite);
}
#footerCont #socialMedia{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#footerCont #socialMedia a{
  margin: .3rem;
}
#footerCont #text{
  color: var(--offWhite);
}


/* LANDING */
#landing{
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-wrap: wrap;
}
#landingCont{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90vw;
}
.landingText{
  display:flex;
  flex-direction: column;
  width: 40rem;
}
#hashTag{
  color: var(--blue);
}
.landingBox img{
  width: 35rem;
  cursor: pointer;
}
#landingBtns button{
  margin-top: 1.5rem;
  margin-inline-end: 1rem;
}


/* COUNT DOWN */
.countdown{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  background-color: var(--black);
  flex-direction: column;
}
.countdown #h1{
  color: var(--offWhite);
  font-size: 45px;
  margin-top: 10px;
}
#countdownCont{
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80vw;
  
}
#countdownCont h3{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #4bbee8;
  padding: .5rem .5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 17vw;
  border-radius: 30px;
}
.countdownText{
  font-family: Paytone One;
  font-size: 50px;
  text-align: center;
  margin-bottom: .5rem;
  color:var(--offWhite);
}
.countdown h3 span{
  margin-top: -5px;
  font-family: IBM Plex Sans;
  font-weight: bold;
  font-size: 26px;
}


/* ABOUT */
#about{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.aboutCont{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90vw;
  height: 33rem;
}
.aboutText{
  display: flex;
  flex-direction: column;
  width: 30rem;
  gap: 1rem;
}
.aboutCont img{
  width: 32rem;
}


/* ROADMAP */
#roadmap{
  margin-top: 8rem;
  background-color: var(--blue);
  padding: 3rem;
}
.roadmapSection{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
}
.roadmapSection img{
  width: 45%;
}
#respRoadmap{
  display: none;
}
#roadmapCont{
  position: absolute;
  width: 95%;

}
#roadmapCont li{
  width: 20rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 12px; /* SPACING BETWEEN TEXT */
}
#line{
  border: 3px solid #000000;
  border-radius: 8px;
}
#roadmapCont li:last-child{
  margin-bottom: 0;
}
#roadmapCont li:nth-child(odd){
  float:left;
  clear: right;
  align-items: right;
  text-align: right;
}
#roadmapCont li:nth-child(even){
  float:right;
  clear: left;

}


/* TEAM */
#team{  
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.teamCont{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
  width: 90vw;
  gap: 3rem;
}
.teamBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 1rem;
}

/* TEXT GENERAL */
.teamWrapper{
  min-width: 100vw;
  z-index: 1;
}
.teamWrapper hr{
  height: 30px;
  border:none;
}
#line1{
  background-color: var(--yellow);
}
#line2{
  background-color: var(--green);
}
#line3{
  background-color: var(--orange);
}
#line4{
  background-color: var(--pink);
}
.teamWrapper #text{  
  animation: infiniteText 3s linear infinite; 
  background-color: var(--blue);
}
@keyframes infiniteText {
  0%{transform:translateX(0)}
  100%{transform: translateX(-12%);}
}


/* FAQ */
#faq{
  margin-top: 8rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.faqCont {
  width: 90vw;
}
.faqBox{
  background-color: var(--blue);
  border-radius: 13px;
  margin: 20px 0;
  padding: 1rem;
  transition: 0.3s ease;
}
.faqBox a{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:var(--black);
}
.faqBox ion-icon{
  padding: .5rem;
}
.faqBox #icon-up{
  display: none;
}
.answer{
  max-height: 0;
  overflow:hidden;
  position: relative;
  transition:max-height 200ms;
}
.faqBox:target .answer{
  max-height: 20rem;
  position: relative;
}
.faqBox:target #icon-down{
 display: none;
}
.faqBox:target #icon-up{
  display: block;
}

#mintSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 33rem;
}

#mintBtn{
display: flex;
justify-content: center;
margin-top:1rem;
height: auto;
}

#mintNFT{    
width: 275vw;
animation: infiniteText 8s linear infinite; 
background-color: var(--blue);
}
@keyframes infiniteText {
0%{transform:translateX(0)}
100%{transform: translateX(-50%);}
}


@media (max-width: 1300px){
  #h1{
    font-size: 40px;
  }
  #text{
    font-size:18px;
  }
  #navLink{
    width: auto;
  }
  #navCont button{
    padding: 8px 15px;
  }
  .landingBox img{
    width: 30rem;
  }

  /* ABOUT */
  .aboutCont{
    height:auto;
    margin-top: 2rem;
    gap: 2rem;
  }
  .aboutText{
    width: 30rem;
  }
  .aboutCont img{
    width: 28rem;
  }
  #roadmapCont li{
    width: 27%;
    margin-bottom: 1rem;
  }
  /* FAQ */
  #faq{
    margin-top: 5rem;
    gap: 1rem;
  }
  .faqCont{
    width: 80%;
  }
}

@media (max-width: 1000px){
  nav #name{
    text-align: center;
    width: 100%;
    margin-top: 1rem;
  }
  #h1{
    font-size: 38px;
  }
  #h2{
    font-size: 33px;
  }
  #text{
    font-size:16px;
  }
  .aboutCont img{
    width: 25rem;
  }
}

@media (max-width: 900px){
  #name{font-size: 25px;}
  #h1{font-size: 30px;}
  /* LANDING */
  #landing{
    width: 100vw;
  }
  .landingBox img{
    width: 24rem;
  }
  #landingBtns button{
    margin-top: 1rem;
    margin-inline-end: .5rem;
  }

    /* ROADMAP */
  #roadmap{
    margin-top: 8rem;
    display: flex;
    justify-content: center;
    align-items:center;
    padding:0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .roadmapSection{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
    flex-direction: row-reverse;
  }
  #roadmapImg{
    display: none;
  }
  #respRoadmap{
    display: flex;
    width: 16rem;
  }
  #roadmapCont{
  position: absolute;
  width:auto;
  left:0;
  margin-left: 2rem;
  }
  #roadmapCont li{
    width: 18rem;
    margin-top: 20rem;
  }
  #roadmapCont #h1{
    display: none;
  }
  #roadmapCont li:last-child{
    margin-bottom: 0;
  }
  #roadmapCont li:nth-child(odd){
    float:none;
    clear: right;
    align-items: right;
    text-align: right;
  }
  #roadmapCont li:nth-child(even){
    float:none;
    clear: left;
    text-align: right;
  }
}

@media (max-width: 750px){
  #name{font-size: 19px;}

  #navLink{
    width: auto;
  }
  #socialMedia img{
    width: 3rem;
    margin: 1px;
  }

  #landing{
    min-height: 90vh;

  }
  #landingCont img{
    display: none;
  }
  .landingText{
    text-align: center;
    width: 90%;
  }

  /* ABOUT */
  .aboutCont{
    flex-direction: column;
    margin-top: 5rem;
  }
  #aboutCont2{
    flex-direction: column-reverse;
  }
  .aboutText{
    text-align: center;
    width: 90%;
  }
  .aboutCont img{
    width: 98%;
    margin-top: 1rem;
  }


  /* TEAM */
  #team{  
    margin-top: 5rem;
    gap: 3rem;
  }
  .teamCont{
    width: 100%;
    gap: 0.5rem;
  }
  .teamBox{
    width: 15rem;
    margin-top: 2rem;
  } 
  .teamBox  img{
    width: 70%;
  }
}

@media (max-width: 680px){
  #name{display: none;}
  nav button{
    display: none;
  }
  #logoNav{display: none;}
  #h5{display: none;}

  nav #socialMedia{
    width:100vw;
    margin:5px;
    justify-content: center;

  }
}



:root{
  --black: #2C2B26;
  --blue: #4bbee8;
  --darkGreen: #47AD6D;
  --offWhite: #F4F4F4;
  --yellow: #FCE300;
  --orange: #FF9A40;
  --green: #49D9B3;
  --pink: #FF7AAB;

}

